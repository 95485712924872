.about__container {
  background-color: var(--light);
  padding-top: 2.25em;
}

.guyImage {
  background: url("../../assets/nod-41mobile.jpg") 100% 50% / cover no-repeat
    scroll;
  height: 360px;
  width: 100%;
}

@media only screen and (min-width: 600px) {
  /* For tablet: */
  .guyImage {
    height: 600px;
    background: url("../../assets/nod-41.jpg") 100% 50% / cover no-repeat fixed;
  }
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
}
