.footerContainer {
  display: flex;
  background-color: #333333;
  align-items: flex-start;
  align-content: space-between;
  justify-content: center;
  flex-direction: row;
  padding: 2em;
  height: 10em;
}

.logo {
  height: 100%;
  margin-right: 2em;
  border-radius: 6px;
}

.footerLink {
  font-size: 1.4em;
  color: var(--brand-light-grey);
  font-family: var(--display-font);
  margin-bottom: 0.2em;
  align-content: space-between;
}
.heading {
  font-size: 1.5em;
  color: var(--light);
  font-family: var(--display-font);
  font-weight: bolder;
  margin-bottom: 0.3em;
}

.footerLinks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 1em;
}

.footerContainer > div:last-child > strong {
  align-self: center;
  text-align: center;
}

a.footerLink {
  display: initial;
  font-size: 1.2em;
}

a.footerLink:link {
  text-decoration: none;
}

a.footerLink:visited {
  text-decoration: none;
}

a.footerLink:hover {
  color: var(--light);
  border-bottom: #f7e27c 1px solid;
}

#footerIconContainer {
  height: 100%;
  align-content: space-between;
}

@media only screen and (min-width: 600px) and (max-width: 700px) {
  .socials {
    display: none;
  }
}
