.background {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(97, 96, 96, 0.5);
  z-index: 51;
}

.spinner {
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
}

.cancel {
  position: absolute;
  padding: 1em;
  border-radius: 4px;
  margin: 2em auto 0 auto;
  background-color: var(--brand-mid-grey);
  color: var(--brand-yellow);
  left: 50%;
  transform: translateX(-50%);
}

.cancel:focus {
  outline-color: var(--brand-yellow);
}
