@media only screen and (max-width: 600px) {
  .background {
    background-color: var(--light);
  }
}

.contactOptions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5em 2em;
}

@media only screen and (min-width: 600px) {
  /* For tablets: */

  .background {
    display: block;
    position: relative;
  }

  .background::after {
    content: "";
    background: url("../../assets/nod-31.jpg") center/cover no-repeat fixed;
    opacity: 0.25;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
}
@media only screen and (min-width: 768px) {
  /* For desktop: */

  .background::after {
    opacity: 0.5;
  }

  .contactOptions {
    padding: 2em 2em 4em 2em;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
