.dimensions {
  display: flex;
  color: #404040;
  flex-wrap: wrap;
  font: 1.2em var(--display-font);
  margin-bottom: 2em;
  font-family: var(--body-font);
}

.headers {
  font-family: var(--display-font);
}

.dimensions > p {
  font: 1.2em var(--display-font);
  margin: 0 1em 2em 1em;
}

.values {
  margin-left: 1.5em;
  font-weight: bold;
}

@media only screen and (min-width: 600px) {
  /* For tablet: */
}

@media only screen and (min-width: 700px) {
  /* For desktop: */
  .dimensions {
    font-size: 1.2em;
  }

  .dimensions > p {
    margin: 0 1em 2em 1em;
  }

  .headers p {
    color: var(--light);
  }
  .values p {
    color: var(--light);
  }
}
