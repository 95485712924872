.shopLinkWrapper {
  font: 1.5em var(--display-font);
  position: fixed;
  top: 0;
  right: 10px;
  border-radius: 5px;
  padding: 4px;
  z-index: 2;
  background-color: var(--brand-yellow);
  height: 61px;
  width: 58px;
  display: flex;
  align-items: center;
}

.shopLinkIcon {
  width: 100%;
  height: auto;
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .shopLinkWrapper {
    display: none;
  }
}
