.selectorGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1.5em;
  font: 0.9em var(--display-font);
  font-variant: small-caps;
}

.selectorGroup div {
  cursor: pointer;
}

@media only screen and (min-width: 600px) {
  /* For tablet: */
}

@media only screen and (min-width: 700px) {
  /* For desktop: */
  .selectorGroup {
    margin-bottom: 1.5em;
    color: var(--dark);
  }
}
