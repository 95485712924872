.logo {
  height: 100%;
  width: auto;
}

.logoContainer {
  height: 100%;
  width: 120px;
}

.leftLinks {
  display: none;
}

.rightLinks {
  display: none;
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
}
@media only screen and (min-width: 768px) {
  /* For desktop: */

  .leftLinks {
    display: flex;
    order: 1;
    width: 45%;
    justify-content: space-around;
  }

  .rightLinks {
    display: flex;
    order: 3;
    width: 45%;
    justify-content: space-around;
  }
  .logoContainer {
    height: 80px;
    width: auto;
    display: inline-block;
    order: 2;
    margin: 0 auto;
    justify-content: center;
  }

  .nav {
    flex: 1;
    display: flex;
    align-items: center;
  }

  a.navLink {
    order: 2;
    font-variant: small-caps;
    letter-spacing: 0.1em;
    color: #f6f7f9;
    font-size: 1.8em;
    border-bottom: rgba(247, 226, 124, 0) 1px solid;
  }

  .linkContainer {
    width: 50%;
    text-align: center;
  }

  a.navLink:link {
    text-decoration: none;
  }

  a.navLink:visited {
    text-decoration: none;
  }

  a.navLink:hover {
    color: var(--brand-light-grey);
    border-bottom: #f7e27c 1px solid;
  }

  a.navLink:focus {
    color: var(--brand-yellow);
  }
}
