input:focus {
  outline-color: var(--brand-yellow);
}
textarea:focus {
  outline-color: var(--brand-yellow);
}

span p {
  line-height: 1.5em;
}

form label {
  font-family: var(--body-font);
  margin-bottom: 0.2em;
  text-align: left;
}

.contactForm {
  font-family: var(--display-font), sans-serif;
  font-size: 1.5em;
  text-align: center;
  padding: 1em 1.5em;
}

.heading {
  display: inline-block;
  border-bottom: var(--brand-yellow) 4px solid;
  margin-bottom: 0.5em;
  font-size: 1.5em;
}

.subHeading {
  font: 0.75em var(--body-font);
  text-align: left;
  margin-bottom: 2em;
  line-height: 1.5em;
}

.fieldRow {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.fieldRow > label {
  width: 100%;
  height: 32px;
  border-radius: 4px;
}

.fieldRow > input {
  width: 100%;
  height: 2em;
  padding: 0.5em;
  border-radius: 4px;
  margin-bottom: 18px;
}

.fieldRow > textarea {
  width: 100%;
  height: 9.5em;
  padding: 0.5em;
  border-radius: 4px;
  margin-bottom: 18px;
}

.submit {
  font: bold 1rem var(--display-font);
  padding: 0.5em 1em;
  border-radius: 10px;
  background-color: #f7e27c;
  box-shadow: 2px 2px var(--dark-grey-screen);
  border: none;
  margin: 1em 0 1.5em auto;
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
}
@media only screen and (min-width: 768px) {
  /* For desktop: */

  .contactForm {
    border: solid 1.5px var(--brand-light-grey);
    border-radius: 6px;
    background-color: rgba(245, 245, 245, 0.65);
    padding: 1em 1.5em;
  }

  .heading {
    margin-bottom: 0.75em;
  }

  .fieldRow > label {
    width: 100%;
    font-size: 0.9em;
    margin-bottom: 0.5em;
  }

  .fieldRow > input {
    width: 100%;
    margin-bottom: 2em;
  }

  .fieldRow > textarea {
    width: 100%;
    margin-bottom: 2em;
  }

  .submit {
    margin: 1em 0 1.5em auto;
    cursor: pointer;
  }
}
