.desktop {
  display: none;
}

.testimonialContainer {
  padding: 3.5em 0;
  background-color: #757575;
}

.testimonialSection {
  display: flex;
  justify-content: center;
}

.testimonials {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.testimonial {
  width: 240px;
  border-radius: 5px;
  margin: 0 1em 8em 1em;
  height: 320px;
  padding: 1.25em 2em;
  background-color: var(--light);
  background-image: url("../../assets/open_quote.png"),
    url("../../assets/close_quote.png");
  background-repeat: no-repeat;
  background-position: left top, right bottom;
  background-origin: content-box;
  position: relative;
  display: flex;
  align-content: center;
}

.testimonialText {
  padding: 1.5em 0 5.5em 0;
  font-family: var(--display-font);
  font-size: 0.75em;
  color: rgb(129, 129, 129);
  line-height: 1.75em;
  display: flex;
  align-self: center;
}

.testimonialText::first-letter {
  font-size: 1.25em;
}

.heading {
  font-size: 2.5em;
  text-align: center;
  font-family: var(--display-font);
  color: #f7e27c;
  padding: 40px;
  margin-bottom: 0.5em;
}

.profileImg {
  background-color: var(--light);
  position: absolute;
  left: 86px;
  top: 302px;
  right: 0px;
  bottom: 0px;
  width: 100px;
  height: 100px;
  padding-top: 0px;
  border-radius: 50%;
  box-shadow: rgb(44, 44, 44) 2px 2px 8px 0px;
}

.more {
  position: absolute;
  left: 190px;
  top: 362px;
  width: 100px;
  height: 100px;
  padding-top: 0px;
  margin: 0px;
  font-family: var(--display-font);
  color: var(--light);
}

.author {
  position: absolute;
  left: 2em;
  bottom: 2.5em;
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .heading {
    font-size: 4em;
  }
}
