.item {
  margin-bottom: 0.5em;
}

.contactTitle {
  font: bold 1.5em var(--display-font);
  margin: 1em 0 0.75em 0;
}

.contactItem {
  margin-bottom: 0.5em;
  font-family: 1.2em var(--body-font);
  text-align: center;
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
}
@media only screen and (min-width: 768px) {
  /* For desktop: */

  .heading {
    font-size: 1.25em;
  }

  .contactTitle {
    font-size: 1.25em;
    margin-bottom: 0.75em;
  }

  .item {
    margin-bottom: 0.25em;
  }
}
