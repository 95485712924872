@import url("https://fonts.googleapis.com/css?family=Lato|Montserrat");

#root {
  --brand-dark-grey: #404040;
  --brand-mid-grey: #616060;
  --brand-light-grey: #acacac;
  --brand-yellow: #f7e27c;
  --light: rgba(250, 250, 250, 1);
  --dark: var(--brand-dark-grey);
  --dark-grey-screen: rgba(64, 64, 64, 0.5);
  --display-font: "Montserrat", sans-serif;
  --body-font: "Lato", sans-serif;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  color: var(--dark);
}

body {
  min-width: 290px;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  margin: 0;
  padding: 0;
}

h2 {
  margin: 0;
  padding: 0;
}

span {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

input,
textarea {
  font: 14px "Nunito";
  padding: 0.75em 0.5em;
  letter-spacing: 0.02em;
  color: #404040;
  line-height: 14px;
}

@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

@media only screen and (min-width: 600px) {
  /* For tablet: */
}

@media only screen and (min-width: 768px) {
  /* For desktop */
}
