.container {
  display: flex;
  flex-direction: column;
  font-family: var(--body-font);
  padding: 1em 0 4em 0;
  background-color: var(--light);
}

.mobileTopTextContainer {
  padding: 2em 2em 2em 2em;
  border: solid 1.5px var(--brand-light-grey);
  border-radius: 6px;
  margin: 1.5em;
}

.mobileTopTextHeader {
  font: 2em var(--display-font);
  text-align: center;
  margin-bottom: 0.5em;
  border-bottom: solid 4px var(--brand-yellow);
}

.mobileTopTextDescription {
  color: 1.4em var(--brand-dark-grey);
  line-height: 1.6em;
  margin-bottom: 2em;
  padding: 0 1em;
  font: 1.05em var(--body-font);
  line-height: 1.5em;
}

.topTextContainer {
  display: none;
  padding: 1.5em 2.5em;
}

.bottomTextContainer {
  padding: 1.5em 2.5em;
}

.header {
  font: 2em var(--display-font);
  text-align: center;
  font-size: 2em;
  padding: 0.25em 0;
  background: var(--brand-dark-grey);
}

.productImg {
  width: auto;
  height: 280px;
  background: url("../../assets/nod-22.png") center / cover no-repeat;
}

.buyContainer {
  background: var(--dark-grey-screen);
  padding: 2em;
  height: 100%;
  min-width: 320px;
  margin: 0 1.5em 0 0;
}

.price {
  font-family: var(--body-font);
  color: #404040;
  font-size: 2.8em;
  text-align: center;
  margin-bottom: 1em;
}

.currency {
  vertical-align: super;
  font-size: 0.5em;
  color: rgba(0, 0, 0, 0.671);
}

.chooseText {
  font: 1.8em var(--display-font);
  margin-top: 1em;
  margin-bottom: 1.5em;
  text-align: center;
}

.buyBtn {
  display: block;
  height: 4em;
  width: 100%;
  align-self: flex-end;
  border-radius: 6px;
  font: bold 1em var(--display-font);
  background-color: #f7e27c;
  border: none;
  margin: 0 auto 0 auto;
  line-height: 1.2em;
  padding: 0.5em;
  box-shadow: 2px 2px var(--dark-grey-screen);
}

.buyBtn:focus {
  outline: none;
  border: 1px var(--dark) solid;
}

.intro {
  text-align: center;
  margin-bottom: 1em;
  font: 0.9em var(--display-font);
}

@media only screen and (min-width: 700px) {
  /* For tablet: */
  .buyBtn {
    cursor: pointer;
    font-size: 1em;
    margin: 0 0 1em auto;
  }

  .price {
    font-family: var(--body-font);
    color: var(--light);
    text-align: center;
    margin-bottom: 1.5em;
  }

  .box .price {
    color: var(--light);
  }

  .header {
    align-self: flex-start;
    justify-content: flex-start;
    font: 4em var(--display-font);
    background: none;
  }

  .intro {
    font: 1.25em var(--display-font);
    color: var(--light);
    text-align: center;
    margin-bottom: 0.25em;
  }

  .chooseText {
    font-size: 1.3em;
    margin-bottom: 1em;
    color: var(--light);
  }

  .container {
    flex-direction: row;
    width: 100%;
    height: calc(100vh - 60px - 180px);
    min-height: 730px;
    background-image: url(../../assets/nod-34.jpg);
    background-size: cover;
    background-position: 100% 40%;
    padding: 0;
    color: var(--light);
  }

  .currency {
    vertical-align: super;
    font-size: 0.5em;
    color: var(--light);
  }

  .detailSpecifics {
    margin-bottom: 0.5em;
  }

  .leftColumn {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 80px;
  }

  .rightColumn {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2em;
  }
  .description {
    font: 0.9em var(--body-font);
    color: var(--light);
    margin-top: 0.75em;
  }
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
}
