.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  position: fixed;
  background-color: #eee;
  z-index: 100;
}

.close {
  width: 48px;
  height: 48px;
  padding: 10px;
}

.closer {
  position: fixed;
  width: 100%;
  background-color: rgba(238, 238, 238, 0.493);
  height: 100vh;
  z-index: 50;
}
