.container {
  padding: 2em 1em 3em 1em;
  background-color: var(--light);
}

.faqs {
  display: flex;
  flex-wrap: wrap;
}

.faqsHeader {
  font-family: var(--display-font);
  display: none;
  margin-bottom: 1em;
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .container {
    padding: 2em 2em 3em 2em;
  }
}

@media only screen and (min-width: 768px) {
  /* For desktop: */

  .container {
    padding: 4em 2em 6em 2em;
  }

  .faqs {
    justify-content: center;
  }

  .faqsHeaderConatiner {
    display: flex;
    justify-content: center;
  }

  .faqsHeader {
    display: inline-block;
    font-size: 2.3em;
    margin-left: auto;
    margin-right: auto;
    font-family: var(--display-font);
    border-bottom: #f7e27c 4px solid;
    text-align: center;
  }
}
