.cart {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 320px;
  background-color: var(--light);
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e5e5e5;
  z-index: 1000;
  transform: translateX(100%);
  transition: transform 0.15s ease-in-out;
}

.cart__open {
  transform: translateX(0);
}

.cart__close {
  position: absolute;
  right: 9px;
  top: 8px;
  font-size: 35px;
  color: #999;
  border: none;
  background: transparent;
  transition: transform 100ms ease;
  cursor: pointer;
}

.cart__header {
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
  flex: 0 0 auto;
  display: inline-block;
}

.cart__lineItems {
  flex: 1 0 auto;
  margin: 0;
  padding: 20px;
  overflow: auto;
  height: calc(100% - 126px - 316px);
}

.cart__footer {
  padding: 20px;
  border-top: 1px solid #e5e5e5;
  flex: 0 0 auto;
}

.cart__checkout {
  margin-top: 20px;
  display: block;
  width: 100%;
  background-color: #f7e27c;
  font-weight: bold;
  height: 40px;
  border-radius: 5px;
}

.cartInfo {
  padding: 15px 20px 10px;
}

.cartInfo__total {
  float: left;
  text-transform: uppercase;
}

.cartInfo__small {
  font-size: 11px;
}

.cartInfo__pricing {
  float: right;
}

.pricing {
  margin-left: 5px;
  font-size: 16px;
  color: var(--dark);
}
