.container {
  padding: 0.5em;
  margin-bottom: 1.5em;
  width: 100%;
  background: #ebebeb;
  font-size: 1em;
  border-radius: 8px;
  font-family: var(--body-font), sans-serif;
  border: solid 1.5px var(--brand-light-grey);
  border-radius: 6px;
}

.question {
  font: bold 1em var(--display-font);
  margin-bottom: 0.25em;
  line-height: 1.25em;
}

.answer {
  font: 1em var(--body-font);
}

.answer::first-letter {
  font-size: 1.2em;
}

.imgContainer {
  display: flex;
  justify-content: center;
}

.speechBubble {
  background: var(--brand-light-grey);
  padding: 1em;
  border-radius: 6px;
  margin-bottom: 1em;
}

.collapse {
  background-color: rgba(255, 255, 255, 0);
  border-bottom: 4px solid var(--brand-yellow);
  cursor: pointer;
  color: #fff;
  padding: 10px;
  margin: 0px;
  max-height: 40px;
  overflow: hidden;
  transition: all 0.4s;
}
.collapse * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.collapse.active {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2);
  z-index: 200;
  color: #444;
  max-height: 3000px;
  padding: 10px 20px;
  margin: 10px -10px;
  transition: all 0.2s, max-height 4.8s;
}
.collapse h2 {
  font-size: 18px;
  position: relative;
}

.transparent {
  background-color: rgba(255, 255, 255, 0) !important;
  color: #fff !important;
  box-shadow: none !important;
  margin: 0px !important;
  padding: 10px !important;
}
.collapse h2::after {
  content: "+";
  text-align: center;
  position: absolute;
  width: 15px;
  height: 15px;
  border: 1px solid #ccc;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1.5em;
  opacity: 0.5;
  right: 0;
  top: 0;
}
.collapse:hover h2::after {
  opacity: 1;
}

.collapse.active h2::after {
  content: "-";
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .container {
    padding: 1.5em;
  }

  .question {
    font: bold 1.1em var(--display-font);
    margin-bottom: 0.25em;
    line-height: 1.5em;
  }
}

@media only screen and (min-width: 768px) {
  /* For desktop */
  .container {
    margin: 30px;
    width: 100%;
  }
  .question {
    font: bold 1.2em var(--display-font);
    margin-bottom: 0.25em;
    line-height: 1.25em;
  }

  .answer {
    font: 1em var(--body-font);
  }

  .answer::first-letter {
    font-size: 1.2em;
  }
}
