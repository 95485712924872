.deliveryImage {
  height: 398px;
  background-image: url("../../../assets/nod-38mobile.jpg");
  background-size: cover;
  background-attachment: scroll;
  overflow: hidden;
  background-position: 55% 80%;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 768px) {
  .deliveryImage {
    width: 100%;
    height: 800px;
    background-image: url("../../../assets/nod-38.jpg");
    background-size: cover;
    background-position: 60% 80%;
    overflow: hidden;
  }
}
