.menuBtn {
  position: fixed;
  width: 3em;
  height: 3em;
  padding: 10px;
  background-image: url("../../assets/material_icons/menu.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: content-box;
  background-color: #f7e27c;
  border-radius: 50%;
  z-index: 5;
}

@media only screen and (min-width: 600px) {
  /* For tablet: */
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .menuBtn {
    display: none;
  }
}
