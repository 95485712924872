.container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.container > a {
  height: 40px;
  width: 40px;
  margin-top: 1em;
}

a span {
  opacity: 0;
}

@media only screen and (min-width: 600px) and (max-width: 700px) {
  .container {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    justify-content: space-around;
  }
}
