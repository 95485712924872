.banner__container {
  background: var(--light);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--brand-light-grey);
  padding: 4em 2em;
}

hr {
  border: 1px solid var(--brand-yellow);
}

.banner__middle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 0 2em;
  font: 0.85em var(--display-font);
}

.banner__text-top {
  text-align: center;
  margin: 1.5em 1em 1em 1em;
  line-height: 1.5em;
}

.banner__text-bottom {
  text-align: center;
  margin: 1em 1em 1.5em 1em;
  line-height: 1.5em;
}

.banner__image-container {
  display: flex;
  align-content: center;
}

.banner__image {
  height: 100px;
  width: auto;
}

@media only screen and (min-width: 600px) {
  /* For tablet: */

  .banner__container {
    background: var(--light);
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--brand-light-grey);
    padding: 4em 2em;
  }

  .banner__middle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 0 2em;
    font: 0.85em var(--display-font);
  }

  .banner__text-top {
    text-align: left;
  }

  .banner__text-bottom {
    text-align: right;
  }

  .banner__image-container {
    display: flex;
    align-content: center;
  }

  .banner__image {
    height: 100px;
    width: auto;
  }
}
