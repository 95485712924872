.LineItem {
  margin-bottom: 1em;
  overflow: hidden;
  backface-visibility: visible;
  min-height: 65px;
  position: relative;
  opacity: 1;
  transition: opacity 0.2s easeIn-out;
}

.lineImg {
  width: 300px;
  border-radius: 3px;
  background-color: #e5e5e5;
  margin-bottom: 10px;
}

.content {
  width: 100%;
  padding-left: 75px;
}

.contentRow {
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
  position: relative;
}

.variantTitle {
  font-weight: bold;
  font-size: 11px;
  line-height: 17px;
  color: #767676;
}

.title {
  color: #4e5665;
  font-size: 15px;
  font-weight: 400;
}

.price {
  line-height: 23px;
  font-weight: bold;
  font-size: 15px;
  margin-right: 40px;
  margin-left: 8px;
}

.quantityContainer {
  border: 1px solid #767676;
  float: left;
  border-radius: 3px;
  margin-right: 1em;
}

.quantityUpdate {
  color: #767676;
  display: block;
  float: left;
  height: 21px;
  line-height: 16px;
  font-family: monospace;
  width: 25px;
  padding: 0;
  border: none;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
}

.quantityUpdateForm {
  display: inline;
}

.quantity {
  color: var(--dark);
  width: 38px;
  height: 21px;
  line-height: 23px;
  font-size: 15px;
  border: none;
  text-align: center;
  -moz-appearance: textfield;
  background: transparent;
  border-left: 1px solid #767676;
  border-right: 1px solid #767676;
  display: block;
  float: left;
  padding: 0;
  border-radius: 0;
}

.remove {
  background: 0;
  font-size: 20px;
  opacity: 0.5;
  margin-left: 1em;
}

.remove:hover {
  opacity: 1;
  cursor: pointer;
}

.productImage {
  height: 120px;
  width: 300px;
  background-size: 100%;
  background-position: -10px 50%;
}
