.hows__container {
  padding: 5em 0;
  background: var(--light);
  border-bottom: solid 0.5em #f7e27c;
}

.header {
  font: 2rem var(--display-font);
  text-align: center;
  margin-bottom: 1.5em;
}

.features {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background: var(--light);
  padding: 0 2em;
  justify-content: space-around;
}

.feature {
  display: flex;
  flex-direction: column;
  font-family: var(--display-font);
  font-weight: bold;
  font-size: 2rem;
  padding: 0.6em 0.5em 0.75em 0.5em;
  background: var(--light);
  line-height: 0.9em;
  margin-bottom: 1em;
}

.feature > img {
  max-width: 100px;
  margin: 0 auto 0.3em auto;
}

.feature > p {
  color: var(--mid-grey);
  font-weight: initial;
  font-size: 0.5em;
  text-align: center;
  max-width: 400px;
}

.featureHeading {
  text-align: center;
  margin-bottom: 0.35em;
  line-height: 1em;
}

.arrow {
  display: none;
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .hows__container {
    padding: 5em 0 2em 0;
  }
  .header {
    margin-bottom: 2em;
  }

  .arrow {
    display: flex;
    align-self: center;
    height: 2em;
    width: auto;
  }
  .feature {
    max-width: 22%;
  }

  .featureHeading {
    text-align: center;
    margin-bottom: 0.5em;
    line-height: 1em;
  }

  .feature > img {
    max-width: 100px;
    margin: 0 auto 0.5em auto;
  }
}
