.footerContainer {
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: var(--brand-dark-grey);
  border-top: var(--brand-yellow) 4px solid;
}

.footerLinks {
  display: flex;
  flex-direction: column;
  text-align: center;
}

a.navLink {
  display: initial;
  color: var(--light);
  font: 1.3em var(--display-font);
  font-variant: small-caps;
  letter-spacing: 0.12em;
  margin-bottom: 0.8em;
  align-self: center;
}

a.navLink:link {
  text-decoration: none;
}

a.navLink:visited {
  text-decoration: none;
}

hr.divider {
  border: 0;
  display: block;
  width: 96%;
  background-color: var(--brand-yellow);
  height: 1px;
  margin-bottom: 0.5em;
}

.sec {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10vw;
}

.secBottom {
  margin-bottom: 0.5em;
  justify-content: center;
}
