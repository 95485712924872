.logo {
  height: 100%;
}

.container {
  display: none;
}

.controlsContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px 0 20px;
}

.controlsContainer > span {
  color: var(--light);
  font-family: var(--body-font), sans-serif;
  font-size: 1.2em;
  font-weight: bold;
  margin-right: 20px;
}

.buy {
  margin-left: 16px;
  height: 32px;
  width: 100px;
  border-radius: 6px;
  background-color: #f7e27c;
  border: none;
  box-shadow: #303030 2px 2px;
  font-family: var(--body-font);
  font-size: 0.85em;
  font-weight: bold;
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .container {
    width: 100%;
    height: 60px;
    background-color: #404040;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 100;
  }
}
