.infoContainer {
  display: flex;
  flex-direction: column;
  padding: 1em;
  height: 100%;
}

.heading {
  display: inline-block;
  margin-bottom: 1em;
  font: bold 2em var(--display-font);
  border-bottom: #f7e27c 4px solid;
}

.contactLink {
  text-decoration: none;
  border-bottom: solid 2px var(--brand-yellow);
  padding: 0.05em 0.35em;
  margin: 2em;
  font: 2em var (--display-font);
}

.contactLink:focus {
}

.contactLink:hover {
  border-bottom: solid 4px var(--brand-yellow);
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
}
@media only screen and (min-width: 768px) {
  /* For desktop: */
  .infoContainer {
    align-items: center;
  }

  .heading {
    font-size: 1.5em;
    margin-bottom: 1em;
  }

  .infoContainer {
    background-color: rgba(245, 245, 245, 0.65);
    border: solid 1.5px var(--brand-light-grey);
    border-radius: 6px;
    padding: 1.5em 0;
    margin: 1.5em 1.5em 1.5em 0;
  }
}
