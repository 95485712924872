@media only screen and (max-width: 767px) {
  .navBarContainer {
    min-width: 266px;
    width: 100%;
    height: 80px;
    display: flex;
    background-color: #f7e27c;
    border-bottom: #404040 8px solid;
    justify-content: center;
  }
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
}
@media only screen and (min-width: 768px) {
  /* For desktop: */
  .navBarContainer {
    width: 100%;
    display: flex;
    background-color: #f7e27c;
    justify-content: space-around;
    background-color: #404040;
    z-index: 20;
    border-bottom: #f7e27c 8px solid;
    height: 80px;
    align-items: center;
    justify-content: space-around;

    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}
