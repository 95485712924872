.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--brand-mid-grey);
}

.spinner-text {
  color: var(--brand-yellow);
}

.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  margin-right: 2em;
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 6px;
  box-sizing: border-box;
  border: 26px solid var(--brand-mid-grey);
  border-color: var(--brand-mid-grey) transparent var(--brand-mid-grey)
    transparent;
  animation: lds-hourglass 1.2s infinite;
  background-color: var(--brand-yellow);
}
