.cartWrapper {
  position: fixed;
  background-color: #f7e27c;
  top: 0px;
  right: 10px;
  z-index: 50;
  border-radius: 5px;
  padding: 4px;
}

.cart {
  width: 50px;
  height: 50px;
  border: none;
  background: none;
  background-image: url(./assets/material_icons/shopping_cart.svg);
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .cartWrapper {
    top: 50%;
    padding: 8px;
    right: 0;
    background-color: #f7e27c;
    box-shadow: rgba(40, 40, 40, 0.4) 2px 2px;
  }
}
