.mainImage {
  display: block;
  width: 100%;
  height: 400px;
  background-image: url("../../../assets/nod-1mobile.jpg");
  /* padding-bottom: 0px; */
  background-size: cover;
  background-attachment: scroll;
  overflow: hidden;
  background-position: 42% 100%;
  /* background-repeat: no-repeat, repeat; */
}

.header {
  margin-top: 0.3em;
  position: absolute;
  font: 2em var(--display-font);
  left: 1.75em;
}

.subHeader {
  /* display: none; */
  margin-top: 3em;
  position: absolute;
  font: 1.15em var(--display-font);
  right: 2.75em;
}

@media only screen and (max-width: 290px) {
  /* For tiny: */

  .subHeader {
    display: none;
  }
}

@media only screen and (min-width: 486px) {
  /* For Big: */

  .subHeader {
    right: 4em;
  }
}

@media only screen and (min-width: 500px) {
  /* For tablets: */
  .header {
    margin-top: 0.75em;
    font: 2.25em var(--display-font);
    /* left: 2.5em; */
    left: 50%;
    transform: translateX(-50%);
  }

  .subHeader {
    margin-top: 4.25em;
    font: 1.25em var(--display-font);
    right: 4em;
    /* left: 50%;
    transform: translateX(-25%); */
  }
  .mainImage {
    height: 600px;
    background-position: 50% 30%, 0px 0px;
    background-image: url("../../../assets/nod-1.jpg");
  }
}

@media only screen and (min-width: 600px) {
  /* For desktop: */
  .header {
    margin-top: 0.45em;
    font: 2.6em var(--display-font);
    left: 50%;
    transform: translateX(-50%);
  }

  .subHeader {
    margin-top: 3.25em;
    font: 1.55em var(--display-font);
    right: 4.5em;
  }
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .header {
    margin-top: 0.25em;
    font: 3.5em var(--display-font);
    left: 50%;
    transform: translateX(-50%);
  }

  .subHeader {
    margin-top: 3.75em;
    font: 1.55em var(--display-font);
    /* right: 4.5em; */
    left: 50%;
    transform: translateX(-50%);
  }
}

@media only screen and (min-width: 875px) {
  /* For Big: */

  .subHeader {
    left: 50%;
    transform: translateX(-25%);
  }
}

@media only screen and (min-width: 1110px) {
  /* For Big: */

  .header {
    margin-top: 0.25em;
    font: 4em var(--display-font);
    left: 50%;
    transform: translateX(-50%);
  }

  .subHeader {
    margin-top: 3.5em;
    font: 1.75em var(--display-font);
    left: 50%;
    transform: translateX(-25%);
  }
}
