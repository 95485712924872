.header {
  font: 1.75em var(--display-font);
  margin-bottom: 1.5em;
  text-align: center;
}

.diagram__container {
  padding: 1.75em 1.5em 3.5em 1.5em;
  display: flex;
  flex-wrap: wrap;
  background-color: var(--light);
  justify-content: center;
  margin-bottom: 3em;
}

.text {
  display: block;
  padding: 0 3em;
  order: 2;
}

.diagram__list {
  font: 1em var(--body-font);
  line-height: 1.2em;
  list-style-position: inside;
  margin-bottom: 3em;
}

.diagram__list > li {
  font: bold 1em var(--display-font);
  list-style-type: decimal;
  color: var(--mid-grey);
}

.image__container {
  display: flex;
  justify-content: center;
}

.image {
  grid-area: "image";
  order: 1;
  margin: 0 auto 3em auto;
  align-self: center;
  width: 100%;
  height: auto;
  max-width: 800px;
}

.logo2 {
  height: 8em;
  width: auto;
  margin-top: 3em;
}

.details > li {
  font: 0.9em var(--display-font);
  list-style-type: square;
  list-style-position: inside;
  line-height: 1.5em;
  margin-bottom: 0em;
}

.details li:last-child {
  margin-bottom: 2em;
}

@media only screen and (min-width: 450px) {
  /* For tablet: */
  .header {
    font-size: 2em;
  }
}
@media only screen and (min-width: 1000px) {
  /* For tablet: */
  .diagram__container {
    padding: 4em 1em 4em 1em;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "text image";
  }
}

@media only screen and (max-width: 1000px) {
  .image {
    width: 400px;
  }

  .img__container {
    justify-content: space-between;
  }
}
@media only screen and (max-width: 400px) {
  .image {
    max-width: 300px;
  }
}

@media only screen and (min-width: 768px) {
  /* For desktop */

  .header {
    font-size: 2.5em;
  }

  .text {
    grid-area: "text";
    font: 1em var(--display-font);
  }

  .logo {
    height: 6em;
    width: auto;
    margin-top: 2em;
  }

  .img__container {
    display: flex;
    align-content: center;
    grid-area: "image";
    margin: 0 2em;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .image {
    min-width: 500px;
    max-width: 600px;
  }

  .diagram__list {
    margin-bottom: 1em;
  }

  .diagram__list > li {
    font-size: 1.25em;
    line-height: 1.5em;
  }

  .details > li {
    font: 1em var(--display-font);
    line-height: 1.75em;
  }
}

@media only screen and (min-width: 1200px) {
  .img__container {
    justify-content: space-between;
  }
}
