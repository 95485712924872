.termsContainer {
  font-family: var(--body-font), sans-serif;
  padding: 2em 4em 4em 4em;
}

.termHeader {
  color: #404040;
  text-align: center;
  font: 1.4em var(--display-font);
  margin-bottom: 1em;
}

li {
  list-style-type: square;
  margin-bottom: 0.75em;
}

.termContent {
  line-height: 1.6em;
  margin-bottom: 1.5em;
}

@media only screen and (min-width: 600px) {
  /* For tablet: */
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .termsContainer {
    font-family: var(--body-font), sans-serif;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.2em;
    padding: 2em 0;
    width: 50%;
  }
  .term {
    margin-bottom: 60px;
  }
}
