.selector {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28%;
  height: 60px;
  border: solid 1px var(--brand-yellow);
  color: var(--light);
  background-color: var(--brand-mid-grey);
}

.selector span {
  color: var(--light);
  font-weight: bold;
}

.selected span {
  color: var(--dark);
}
.selected {
  background-color: #f7e27c;
}

@media only screen and (min-width: 600px) {
  /* For tablet: */
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .selector {
    min-width: 80px;
    height: 40px;
  }
  .selector:hover {
    background-color: #f7e37cb9;
  }
}
