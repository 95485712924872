.spacer {
  height: "auto";
  width: "auto";
  display: "flex";
  justify-items: "center";
  background-color: blue;
}

.spacerText {
  text-align: center;
  margin: 0;
  padding: 16px;
  font-size: 1.3em;
  font-family: var(--display-font);
}

@media only screen and (min-width: 768px) {
  .spacer {
    display: none;
  }
}
